<template>
  <base-section id="affiliates" space="50">
    <v-container>
      <p class="text-center text-h3">
        Kami berencana akan membuka jaringan layanan di beberapa kota besar di
        Indonesia
      </p>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionNetwork",
  data: () => ({}),
  created() {},
  methods: {},
};
</script>
<style></style>
